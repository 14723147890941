import { Controller } from "@hotwired/stimulus"
var fields = 0

export default class extends Controller{
  static targets = ["type"]
  static values = ["field"]

  connect(){
    this.resourceType()
  }

  resourceType(){
    var type = this.element.getElementsByClassName('resource-type-field')[0].value
    var resourceForm = this.element.getElementsByClassName('resource-type-form')
    var resourceForm = resourceForm[0].getElementsByClassName('domain-record')[0]
    console.log(type)
    console.log(resourceForm)
    if (type == "Domain Record"){
      var element = this.element.getElementsByClassName('additional-form')
      for (var i =0; i < element.length; i++){
        element[i].hidden= true
      }
      var element = this.element.getElementsByClassName('domain-record')[0]
      element.hidden= false
    }
    if (type == "Database Credentials"){
      var element = this.element.getElementsByClassName('additional-form')
      for (var i =0; i < element.length; i++){
        element[i].hidden= true
      }
      var element = this.element.getElementsByClassName('database-credentials')[0]
      element.hidden= false
    }
    if (type == "NaN"){
      var element = this.element.getElementsByClassName('additional-form')
      for (var i =0; i < element.length; i++){
        element[i].hidden= true
      }
    }
  }

  addNewCustomField(){
    fields = fields + 1
    var type = this.element.getElementsByClassName('custom-fields')[0]
    type.innerHTML += `<div class="`+fields+`"><h4>
    Custom Field
    </h4>
    <div class='row'>
    <label for="_resources_field_label">Field label</label>
    <input type="text" name="/resources[field_label`+ fields +`]" id="_resources_field_label" />
    </div>
    <div class='row'>
    <label for="_resources_value">Value</label>
    <input type="text" name="/resources[value`+ fields +`]" id="_resources_value" />
    </div>`

    console.log(type)
    console.log(fields)
  }

  removeCustomField(){
    if (fields != 0){
      var type = this.element.getElementsByClassName(String(fields))[0]
    type.parentNode.removeChild(type)
    fields = fields - 1
    }

  }

}



