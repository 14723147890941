import { Controller } from "@hotwired/stimulus"
var edit = false

export default class extends Controller {
  static targets = ["select", "date", "time", "desc"]

  static values = {
    timeEntryId: Number
  }

  connect(){
    var time = this.data.get('time')

    //this.element.innerHTML = '<div class="rtable-cell.rtable-cell--head"><strong>' + time + '</strong></div>' + this.element.innerHTML
  }

  switch(){
    var deliverable = this.data.get('deliverable')
    if(edit == false){
      edit = true;
      var sections = this.element.getElementsByClassName('item')
      console.log(sections)
      console.log(sections[0])

      //set Date
      //var dateValue = sections[0].getElementsByClassName('strong')[0].textContent
      //sections[0].innerHTML = `<input class='form-control' type='date' value='${dateValue}' data-action='change->invoices#setStatus' data-invoices-target='date'></input>`

      //set Task
      //var taskValue = sections[2].getElementsByClassName('strong')[0].textContent
      //var taskInput = `<select id='task_client_id' class='form-control tomselected ts-accessible' name='task[client_id]' data-select-tasks-target='task' tabindex='-1'>`+`</select>`
      //console.log(deliverable)
      //var taskInput = `<div>{client.name}</div>`
      //sections[2].innerHTML = taskInput

      var time = sections[3].getElementsByClassName('strong')[0].textContent
      time = time.split('/')
      console.log(time[0])
      sections[3].innerHTML = `<input id="client_1_time" class="form-control time-entry" type="text" name="time_entry[total_time]" maxlength="5" value="${time[0]}" data-invoices-target='time'></input>`

      sections[4].innerHTML = `<input id='time_entry_desc' class="form-control" type='text' name='time_entry[desc]' data-select-tasks-target='description' value='${sections[4].getElementsByClassName('strong')[0].textContent}' data-invoices-target='desc'></input>`

      var options = sections[5].getElementsByClassName('strong')[0].textContent
      console.log(options)
      if(options == 'closed'){
        options = `<option value='open'>Open</option>
                  <option value='charge'>Charge Now</option>
                  <option selected='selected' value='closed'>Closed</option>`
      }else if(options == 'charge'){
        options = `<option value='open'>Open</option>
                  <option selected='selected' value='charge'>Charge Now</option>
                  <option value='closed'>Closed</option>`
      }else{
        options = `<option selected='selected' value='open'>Open</option>
                  <option value='charge'>Charge Now</option>
                  <option value='closed'>Closed</option>`
      }


      sections[5].innerHTML = `<select value='${this.data.get('status')}' id='time_entry_status' class='form-control actions' name='time_entry[status]' data-invoices-target='select'>
      ${options}
      </select>`


      //document.getElementById('dateInput').value = dateValue

      //sections[1].innerHTML = "<select>  </select>"
      //<%= select_tag("deliverable[client_id]", options_from_collection_for_select(@clients, 'id', 'name'), prompt: 'Select Client', class: 'form-control  deliverable-client chosen-select-detail', data: { select_tasks_target: 'user'}) %>
    }else{
      var sections = this.element.getElementsByClassName('form-control')
      console.log(sections)
      if(sections.length != 0){
        var newSections = this.element.getElementsByClassName('item')
        console.log(newSections)
        edit = false
        this.setStatus()
        //newSections[0].innerHTML = '<strong class="strong">'+ sections[0].value +'</strong>'
        //newSections[2].innerHTML = '<strong class="strong">'+ sections[0].value +'</strong>'
        var converted_time = this.timeTarget.value.replace(':','.')
        converted_time = converted_time.split('.')
        var hour = converted_time[0]
        var minute = converted_time[1]
        console.log(minute)
        if(minute == null){
          minute = 0
        }
        if(minute.length == 1){
          minute = minute +'0'
        }
        hour = parseFloat(hour)
        minute = parseFloat(minute)
        minute = parseFloat((minute / 60).toFixed(2))
        converted_time = hour + minute
        console.log(converted_time)
        newSections[3].innerHTML = `<strong class="strong">${this.timeTarget.value} / ${converted_time}</strong>`
        newSections[4].innerHTML = '<strong class="strong">'+ sections[0].value +'</strong>'
        newSections[5].innerHTML = '<strong class="strong">'+ sections[0].value +'</strong>'
      }
    }


  }

  back(){
    console.log('SWITCH')
  }

  setStatus(){
    var converted_time = this.timeTarget.value.replace(':','.')
    converted_time = parseFloat(converted_time)
    converted_time = converted_time / 60
    fetch(`/time_entries/${this.timeEntryIdValue}`, {
      method: 'PATCH',
      headers: {
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        "Content-Type": "application/json",
        "Accept": "application/json"
      },

      body: JSON.stringify(
        {
          time_entry: {
            status: this.selectTarget.value,
            total_time: this.timeTarget.value,
            //converted_time: converted_time,
            description: this.descTarget.value
            //entry_date: this.dateTarget.value
          }
        }
      ),
    })
    .then(response => response.json())
    .then(result => console.log(result))
  }
}
