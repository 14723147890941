import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
    //setTimeout(this.remove(), 6000)
  }

  remove(){
    //console.log('DAN')
    var notif = this.element.getElementsByClassName('notification-container')
    //console.log(notif[0])
    notif[0].innerHTML = ''
  }
}
