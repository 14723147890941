import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {

  }

  onChange(){
    var x = this.element.getElementsByClassName("file-upload");
    console.log(x[0].files[0].name);
    var y = this.element.getElementsByClassName("file-upload-text")
    console.log(y[0])
    y[0].innerHTML = "<h5>"+x[0].files[0].name+"</h5>"
  }

}