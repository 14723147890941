import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['timeChange']
  connect() {
    var foo = this.timeChangeTarget

    $(foo).bind("keypress", function (e) {
      var keyCode = e.which ? e.which : e.keyCode
      var $this = $(foo);
        if($this.val().length == 2){
          $this.val($this.val() + ":");
        }

      if (!(keyCode >= 45 && keyCode <= 57)) {
        $(".error").css("display", "inline");
        return false;
        }else{
          $(".error").css("display", "none");
        }
    });

  }
}

