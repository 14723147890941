// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

//import StimulusReflex from 'stimulus_reflex'
//import reflexConsumer from '../channels/consumer'
//import reflexController from '../controllers/application_controller'
import { Application } from '@hotwired/stimulus'

import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'


const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
//StimulusReflex.initialize(application, { consumer, controller, isolate: true })
//StimulusReflex.debug = process.env.RAILS_ENV === 'development'

// Import and register all TailwindCSS Components
// import { Alert } from "tailwindcss-stimulus-components"
// application.register('alert', Alert)
