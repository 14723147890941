import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    var date = this.data.get('input')
    console.log(date)
    //this.element.innerHTML = date
  }

  switch(){
    console.log('SWITCH')
  }
}
