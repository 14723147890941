import { Controller } from "@hotwired/stimulus"
var count = 1

export default class extends Controller {

  addItem(){
    var sections = this.element.getElementsByClassName('sections')[0]
    var rowCount = this.element.getElementsByClassName('WorkItemCount')[0]

    var row = `<div class="row">
    <label class="${count + parseInt(rowCount.value)}" "for="label${count + parseInt(rowCount.value)}">Label</label>
    <input type="text" class="${count + parseInt(rowCount.value)}" id="label${count + parseInt(rowCount.value)}" name="label${count + parseInt(rowCount.value)}">
    <label class="${count + parseInt(rowCount.value)}" for="rate${count + parseInt(rowCount.value)}">Rate</label>
    <input type="number" class="${count + parseInt(rowCount.value)}" id="rate${count + parseInt(rowCount.value)}" name="rate${count + parseInt(rowCount.value)}">
    <br/>
    <div class="button red ${count + parseInt(rowCount.value)}" data-action="click->customizeWorkItem#removeItem" data-value="${count + parseInt(rowCount.value)}">Remove Item</div>
    </div>
    `

    count = count +1
    sections.innerHTML = sections.innerHTML + row
  }

  removeItem(event){
    event.preventDefault()
    const value = event.target.dataset.value
    var sections = this.element.getElementsByClassName(value)
    console.log(sections)
    for (var i = 0; i < sections.length; i++){
      sections[i].parentNode.textContent = ""
    }
  }

}