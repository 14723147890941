import { Controller } from "@hotwired/stimulus"
var count = 0

export default class extends Controller {
  static targets = [ "output" ]

  connect() {

  }

  addRow(){
    var sections = this.element.getElementsByClassName('sections')[0]
    var rows = sections.getElementsByClassName('rows')
    var rowCount = this.element.getElementsByClassName('dashboardCount')[0]


    var row = `<div class='row'>
    <select name="section1_${count + parseInt(rowCount.value)}" id="section1_${count + parseInt(rowCount.value)}" class=""><option value="NaN">NaN</option>
    <option value="Clients">Clients</option>
    <option value="Projects">Projects</option>
    <option value="Deliverables">Deliverables</option>
    <option value="Tasks">Tasks</option>
    <option value="Time Entries">Time Entries</option>
    <option value="Invoices">Invoices</option>
    <option value="Providers">Providers</option>
    <option value="File Uploader">File Uploader</option>
    <option value="Calendar">Calendar</option>
    <option value="To Dos">To Dos</option>
    </select>
    <select name="section2_${count + parseInt(rowCount.value)}" id="section2_${count + parseInt(rowCount.value)}" class=""><option value="NaN">NaN</option>
    <option value="Clients">Clients</option>
    <option value="Projects">Projects</option>
    <option value="Deliverables">Deliverables</option>
    <option value="Tasks">Tasks</option>
    <option value="Time Entries">Time Entries</option>
    <option value="Invoices">Invoices</option>
    <option value="Providers">Providers</option>
    <option value="File Uploader">File Uploader</option>
    <option value="Calendar">Calendar</option>
    <option value="To Dos">To Dos</option>
    </select>
    </div>`
    count = count +1
    sections.innerHTML = sections.innerHTML + row

  }

  removeRow(){
    var sections = this.element.getElementsByClassName('sections')[0]
    var rows = sections.getElementsByClassName('row')
    if(rows.length != 1){
      rows[rows.length - 1].parentNode.removeChild(rows[rows.length - 1])
      count = count - 1
    }

  }

  check(){
    var sections = this.element.getElementsByClassName('sections')[0]
    console.log(sections.innerHTML)
  }

}