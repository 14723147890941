// app/javascript/packs/application.js
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

require("channels")
require('jquery')

require("controllers")
require("tom-select/dist/css/tom-select.bootstrap5")

require("stylesheets/application.scss")

//require.context("../images", true);
require.context("../fonts", true);

import { Foundation } from 'foundation-sites'

Foundation.addToJquery($);

document.addEventListener('turbolinks:load', () => $(document).foundation())

//import "../stylesheets/application.scss";


import "controllers"

console.log("This is working")