import { Controller } from "@hotwired/stimulus"
var edit = false

export default class extends Controller {
  static targets = ["organization", "budget"]

  static values = {
    timeEntryId: Number
  }

  connect(){
    var time = this.data.get('time')

    //this.element.innerHTML = '<div class="rtable-cell.rtable-cell--head"><strong>' + time + '</strong></div>' + this.element.innerHTML
  }

  switch(){
    var id = this.data.get('id')
    var org = this.data.get('organization').toLowerCase()
    console.log(org)
    if(edit == false){
      edit = true;
      var sections = this.element.getElementsByClassName('item')
      console.log(sections)

      sections[4].innerHTML = `<input id='time_entry_desc' class="form-control" type='number' name='time_entry[desc]' data-budget-tasks-target='budget' value='${sections[4].textContent}' data-clientUpdate-target='budget'></input>`
    }else{
      var sections = this.element.getElementsByClassName('form-control')
      console.log(sections)
      if(sections.length != 0){
        var newSections = this.element.getElementsByClassName('item')
        var val = sections[0].value
        newSections[4].innerHTML = sections[0].value
        edit = false
        console.log(org)
        console.log(id)
        console.log(val)
        this.setStatus(org, id, val)
      }
    }
  }

  back(){
    console.log('SWITCH')
  }

  setStatus(org, id, val){
    fetch(`/organizations/${org}/clients/${id}`, {
      method: 'PATCH',
      headers: {
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        "Content-Type": "application/json",
        "Accept": "application/json"
      },

      body: JSON.stringify(
        {
          client: {
            budget_cents: val
          }
        }
      ),
    })
      .then(response => response.json())
      .then(result => console.log(result))
  }
}
