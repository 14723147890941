import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"
import { get, post, put, patch, destroy } from '@rails/request.js'


export default class extends Controller {
  static targets = ['task', 'deliverable', 'description', 'date', 'time', 'client']

  static values = { clientId: Number }


  connect(){
    // new TomSelect(this.userTarget, { create: false})
    // new TomSelect(this.taskTarget, {persist: false, create: true})
    // new TomSelect(this.deliverableTarget, {persist: false, create: true})
    new TomSelect(this.clientTarget, {persist: false, create: true})
  }

  // async addLineItem(){
  //   const response = await post(`/tasks/${this.taskTarget.value}/time_eintries`,
  //     {
  //       contentType: "application/json",
  //       body: JSON.stringify({
  //         time_entry: {
  //           entry_date: this.dateTarget.value,
  //           description: this.descriptionTarget.value,
  //           deliverable_id: this.deliverableTarget.value,
  //           task_id: this.taskTarget.value,
  //           total_time: this.timeTarget.value,
  //           user_id: this.userTarget.value
  //         }
  //       })
  //     })
  //   if (response.ok) {
  //
  //   }
  // }

  addLineItem(){
    fetch(`/tasks/${this.taskTarget.value}/time_entries`, {
      method: 'POST',
      headers: {
        "X-CSRF-Token": document.querySelector("[name='csrf-token']")?.content,
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
      body: JSON.stringify(
        {
          time_entry: {
            entry_date: this.dateTarget.value,
            description: this.descriptionTarget.value,
            deliverable_id: this.deliverableTarget.value,
            task_id: this.taskTarget.value,
            total_time: this.timeTarget.value,
            user_id: this.userTarget.value
          }
        }
      ),
    })
    .then(response => response.json())
    .then(result => console.log(result))
  }
}
