import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"
import { get, post, put, patch, destroy } from '@rails/request.js'


export default class extends Controller {
  static targets = ['client', 'project', 'deliverable', 'task']
  static values = { org: String }
  connect(){
    let org = this.orgValue
    let client
    let project
    let deliverable
    let task

    function bar(selectTom, results){
      selectTom.enable()
      results.forEach( element => {
        selectTom.addOption([{ text: element['name'], value: element['id'] }])
      } )
    }

    let clientSelect = new TomSelect(this.clientTarget, {
      persist: true,
      create: false,
      onChange: function(value) {
        client = value
        projectSelect.clear();
        projectSelect.clearOptions();
        deliverableSelect.clear();
        deliverableSelect.clearOptions();
        taskSelect.clear();
        taskSelect.clearOptions();

        fetch(`/projects`, {
          method: 'GET',
          headers: {
            "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        })
          .then(response => response.json())
          .then(result => bar( projectSelect, result ))


      } // onChange
    })

    let projectSelect = new TomSelect(this.projectTarget, {
      persist: true,
      create: false,
      onChange: function(value) {
        taskSelect.clear();
        taskSelect.clearOptions();
        deliverableSelect.clear();
        deliverableSelect.clearOptions();

        fetch(`/deliverables`, {
          method: 'GET',
          headers: {
            "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        })
          .then(response => response.json())
          .then(result => bar( deliverableSelect, result ))

      } // onChange
    })


    let deliverableSelect = new TomSelect(this.deliverableTarget, {
      persist: true,
      create: false,
      onChange: function(value) {
        deliverable = value
        taskSelect.clear();
        taskSelect.clearOptions();

        fetch(`/tasks`, {
          method: 'GET',
          headers: {
            "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        })
          .then(response => response.json())
          .then(result => bar( taskSelect, result ))

      } // onChange
    })

    let taskSelect = new TomSelect(this.taskTarget, {
      persist: true,
      create: false,
    })

  }
}
